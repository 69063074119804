import { Injectable, Injector } from '@angular/core';
import {
    AutoGenerarRequest,
    AutoGenerarResult,
    CambioReserva,
    CambioReservaBulk,
    Viaje,
    ViajeBulkData,
    ViajeSwapRequest,
    ComentarioViajes,
    ViajeUpdateBulkRequest
} from '@obrador/api-interfaces';
import { catchError, Observable } from 'rxjs';
import { Resource } from '../rest.resources';
import { RestService } from '../rest.service';

@Injectable({
    providedIn: 'root'
})
export class ViajeService extends RestService<Viaje> {

    constructor(protected injector: Injector) {
        super(injector, Resource.VIAJES);
    }

    bulk(bulkData: ViajeBulkData): Observable<any> {
        return this.http.post(`${this.buildURL()}/bulk`, bulkData)
            .pipe(
                catchError(err => this.handleError(err))
            );
    }

    bulkUpdate(bulkData: ViajeUpdateBulkRequest): Observable<any> {
        return this.http.post(`${this.buildURL()}/bulk-update`, bulkData)
            .pipe(
                catchError(err => this.handleError(err))
            );
    }

    remove(id_viaje: number, id_motivo: number): Observable<any> {
        return this.http.delete(`${this.buildURL()}/${id_viaje}`, {
            body: {
                motivoId: id_motivo
            }
        });
    }

    bulkRemove(ids: number[], id_motivo: number): Observable<any> {
        return this.http.delete(`${this.buildURL()}/bulk-remove`, { body: { ids, motivoId: id_motivo } })
            .pipe(
                catchError(err => this.handleError(err))
            );
    }

    swap(request: ViajeSwapRequest): Observable<Array<Viaje>> {
        return this.http.post<Array<Viaje>>(`${this.buildURL()}/swap`, request);
    }

    cambioReserva(dto: CambioReserva): Observable<Viaje> {
        return this.http.patch<Viaje>(`${this.buildURL()}/cambio-reserva`, dto);
    }

    cambioReservasBulk(dto: CambioReservaBulk): Observable<Viaje[]> {
        return this.http.patch<Viaje[]>(`${this.buildURL()}/cambio-reserva-bulk`, dto);
    }

    bulkComentarioUpdate(bulkData: ComentarioViajes): Observable<Viaje[]>{  
        return this.http.patch<Viaje[]>(`${this.buildURL()}/bulk-comentario`, bulkData)
        .pipe(
            catchError(err => this.handleError(err))
        );
    }

    recalcular(dto: { id_empieza: number }): Observable<Array<Viaje>> {
        return this.http.post<Array<Viaje>>(`${this.buildURL()}/recalculate`, dto)
    }

    validar(ids: number[], noGenerarRemitoDigital?: boolean, comentario?: string): Observable<Viaje> {
        return this.http.patch<Viaje>(`${this.buildURL()}/validar`, {
            ids,
            noGenerarRemitoDigital,
            comentario
        });
    }

    cambiarTipo(id: number, operativo?: boolean) : Observable<Viaje> { 
        return this.http.patch<Viaje>(`${this.buildURL()}/cambiar-tipo`, {
            id,
            operativo
        });
    }

    autoGenerarViajes(req: AutoGenerarRequest): Observable<AutoGenerarResult> {
        return this.http.post<AutoGenerarResult>(`${this.buildURL()}/autogenerar`, req);
    }

}
